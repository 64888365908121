import Vue from 'vue'; // 引入VUE核心库
import Vuex from 'vuex'// 引入VUEX核心库  版本号在3.6.2以下的vuex适用于vue2
import newApi from '../utils/newApi';

/**
 * vuex 是一个对数据进行集中式管理的工具
 * 1.数据传值繁琐问题
 * 2.实现了数据的共享
 * 我在组件 a 中用到这个方法，我在组件 b 中也用到这个方法，那么就可以把这个方法统一交给 vuex去完成。如果没有vuex，那我的组件a和b都要单独来设置一个方法 比如判断全局的登录状态
 * 由五部构成
 * 1. state：数据状态-------------------------------------  仓库当中的商品
 * 2. mutations：函数，同步操作。更改state----------------   工人。用来操作商品
 * 3. actions:函数，异步操作。通过调用mutations来更改数据---  老板，发送命令
 * 4. getters:计算属性
 * 5. modules：模块化。
* */

Vue.use(Vuex); // 安装VUEX

export default new Vuex.Store({  // 创建了一个VUEX仓库。

    // 数据状态
    state: {
        is_login:0,
        user:{
            nickName:"未登录",
            bgColor:"666",
            headIco:"https://baigongbao.oss-cn-beijing.aliyuncs.com/img/head.png",
            status:"1",

        },
        // devApiUrl:"https://devservice.baigongbao.com/Api",
        devApiUrl:"http://localhost:7979/Api",

        upload_info:[],
        ossUrl : "https://oss.baigongbao.com/",//oss上传回调使用
    },

    // 更改数据状态。通过mutations来更改数据状态是唯一的正途。是一个函数，函数的名字即是mutations的名字。建议大写。
    mutations: {
        //登录以后刷新用户信息
        get_user_info(state,CallBack){
            if(localStorage.token != '' && typeof localStorage.token != 'undefined'){
                newApi.getUserInfo({}).then((res)=>{
                    state.user = res.data;
                    state.is_login = 1;
                    if (typeof(CallBack) == "function") {
                        CallBack(res.data);
                    }
                })
            }else {
                state.is_login = 0;
                state.user={}
                window.localStorage.clear();
            }
        },

        //upload_info 存储App.vue首页进度条所需参数
        set_upload_info(state,ui){
            var has = 0;
            for (var i = state.upload_info.length - 1; i >= 0; i--) {
                if (state.upload_info[i].name == ui.name) {
                    has = 1;
                    state.upload_info[i].percent = ui.percent;
                    if (ui.percent == 100) {
                        state.upload_info.splice(i,1);
                        return;
                    }
                }
            }
            if (has == 0 && ui.percent != 100) {
                state.upload_info.push(ui);
            }
        },
    },

    actions: {      },   // 行为。异步操作。通过该操作可以获得新的数据，得到数据以后可以通过调用mutations来更改数据状态。


    modules: {      },   //模块化

    getters:{       }    //计算属性


})

